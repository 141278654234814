<template>
  <v-row>
    <v-col cols="12">
      <base-card>

        <v-card-title>
          <router-link
            :to="`/app/administration/tefConfig`"
            @click.native=""
          >
            <v-icon
              class="mr-2"
              color="red darken-2"
              right
            >
              mdi-arrow-left-bold
            </v-icon>
          </router-link>
          Terminales [ {{ getListTerminalesbyTypeTef[0]['client'] }}]
        </v-card-title>
        <v-alert
          v-show="copyAlert"
          type="info"
          text
        >
          {{ msgtokencopiado }}
        </v-alert>
        <br>
        <v-card-text>
          <v-data-table
            :footer-props="{
              'items-per-page-text': 'filas por pagina',
            }"
            :headers="headers"
            :items="getListTerminalesbyTypeTef"
            :search="search"
            class="elevation-1"
          >

            <template v-slot:[`item.token_key`]="{ item }">
              <v-icon
                v-clipboard:copy="item.token_key"
                v-clipboard:success="onCopy"
                style="cursor:pointer;"
                color="info darken-3"
                class="mr-2"
              >
                mdi-content-copy
              </v-icon>
            </template>

            <template v-slot:[`item.data_integration`]="{ item }">
              <v-dialog
                max-width="1000"
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="info darken-3"
                    style="cursor:pointer;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-cellphone-check
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      dark
                      style="background-color:rgb(204 33 40);color:white !important"
                    >
                      DATOS INTERAGACIÓN TEF - CLIENTE [{{ item.client }}] - TERMINAL [{{ item.code_store }}]

                      <v-icon
                        style="margin-left: 460px !important;"
                        @click="dialog.value = false"
                      >mdi-close
                      </v-icon>

                    </v-toolbar>

                    <campo-smart-pos
                      v-if="item.type_tef == 1"
                      :dataTef=JSON.parse(item.data_integration)
                      :token_key=item.token_key
                      :code_store=item.code_store
                    />

                    <campo-credibanco-apps
                      v-if="item.type_tef == 2"
                      :dataTef=JSON.parse(item.data_integration)
                      :token_key=item.token_key
                      :code_store=item.code_store
                    />

                    <campo-credibanco-tramas
                      v-if="item.type_tef == 4"
                      :dataTef=JSON.parse(item.data_integration)
                      :token_key=item.token_key
                      :code_store=item.code_store
                    />

                  </v-card>

                </template>
              </v-dialog>
            </template>

            <template v-slot:[`item.editar`]="{ item }"
            >

              <v-dialog
                max-width="600"
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="orange darken-3"
                    style="cursor:pointer;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      dark
                      style="background-color:rgb(204 33 40);color:white !important"
                    >
                      TIEMPOS CONSULTA TEF - CLIENTE [{{ item.client }}] - TERMINAL [{{ item.code_store }}]

                      <v-icon
                        style="margin-left: 80px !important;"
                        @click="dialog.value = false"
                      >mdi-close
                      </v-icon>

                    </v-toolbar>
                    <v-card-text>

                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-subheader class="pl-0">
                            Tiempo Inicial de Consulta
                          </v-subheader>
                          <br>
                          <v-slider
                            v-model="dataTimeOuts.time_out_initial"
                            :thumb-size="24"
                            min="20"
                            max="60"
                            color="red"
                            track-color="gray"
                            thumb-label="always"
                          />

                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-subheader class="pl-0">
                            Tiempo Secuencial de Consulta
                          </v-subheader>
                          <br>
                          <v-slider
                            v-model="dataTimeOuts.time_out_sequential"
                            :thumb-size="24"
                            min="4"
                            max="20"
                            color="red"
                            track-color="gray"
                            thumb-label="always"
                          />
                        </v-col>

                      </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end">

                      <v-btn
                        text
                        style="background-color:rgb(204 33 40);color:white !important"
                        @click="updateDataTimeout(item)"
                      >
                        Actualizar
                      </v-btn>

                    </v-card-actions>
                    <v-alert
                      v-show="successAlert"
                      type="success"
                      text
                    >
                      {{ msgSuccessAlert }}
                    </v-alert>
                  </v-card>

                </template>
              </v-dialog>
            </template>

          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    CampoSmartPos: () => import('./Credibanco/CampoSmartPos'),
    CampoCredibancoApps: () => import('./Credibanco/CampoCredibancoApps'),
    CampoCredibancoTramas: () => import('./Credibanco/CampoCredibancoTramas'),
  },
  name: 'ShowCredentialTerminalesTef',
  data () {
    return {
      progress: false,
      search: '',
      copyAlert: false,
      dataTimeOuts: [],
      successAlert: false,
      msgtokencopiado: 'Token Copiado',
      msgSuccessAlert: 'Datos actualizados exitosamente',
      headers: [
        {
          text: 'Token Key',
          value: 'token_key',
        },
        {
          text: 'Cod Terminal Hiopos',
          value: 'code_store',
          align: 'center',
        },
        {
          text: 'Datos TEF',
          value: 'data_integration',
        },
        {
          text: 'Tiempo inicial de consulta',
          value: 'time_out_transaction',
          align: 'center',

        },
        {
          text: 'Tiempo secuencial de consulta',
          value: 'time_out_sequential',
          align: 'center',

        },
        {
          text: 'Editar',
          value: 'editar',

        },
        {
          text: 'Fecha Creación',
          value: 'ts_created',
        },
        {
          text: 'Usuario Creación',
          value: 'user_created',
        },
        {
          text: 'Fecha Actualización',
          value: 'ts_updated',
        },
        {
          text: 'Usuario Actualización',
          value: 'user_updated',
        },
      ],

    }
  },
  computed: {
    ...mapState('credentialsTef', [
      'listTerminales','updateStatusTimeOutResponse'
    ]),

    getListTerminalesbyTypeTef(){
      return this.$store.state.credentialsTef.listTerminales.filter(el=> el.type_tef == this.$route.query.Tef)
    },
    updateStatusTimeOutResponse () {
      return this.$store.state.credentialsTef.updateStatusTimeOutResponse
    },

  },
  watch:
    {
      updateStatusTimeOutResponse  () {
        if (this.updateStatusTimeOutResponse == true) {
          this.generateAlertSuccess()
          this.$store.dispatch('credentialsTef/setUpdateResponseTimeOut', false)
        }
      },
    },
  created () {
    this.getListTerminales()
  },
  methods: {
    async getListTerminales () {
      await this.$store.dispatch('credentialsTef/getListTerminales', this.$route.query)
    },
    generateAlertSuccess () {
      this.successAlert = true
      setTimeout(() => {
        this.successAlert = false
      }, 3000)
    },
    onCopy (e) {
      this.generateAlertCopy()
    },
    generateAlertCopy () {
      this.copyAlert = true
      setTimeout(() => {
        this.copyAlert = false
      }, 2000)
    },
    async updateDataTimeout (item) {
      this.dataTimeOuts.token_key = item.token_key
      this.dataTimeOuts.code_store = item.code_store
      this.dataTimeOuts.user = JSON.parse(atob(localStorage.getItem('uid'))).user
      this.dataTimeOuts.observation = 'Updated Timeouts'
      await this.$store.dispatch('credentialsTef/updateCredentialsTimeOut', this.dataTimeOuts)
    },
  },
}
</script>


